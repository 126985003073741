import React from 'react'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Link as MuiLink, Avatar } from '@mui/material'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { red } from '@mui/material/colors'
import CancelIcon from '@mui/icons-material/Cancel'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'

const ExternalLink = ({ href, children }) => {
  return (
    <MuiLink
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      sx={{
        display: 'inline-flex',
        alignItems: 'center',
        gap: 0.5,
      }}
    >
      <OpenInNewIcon fontSize="small" />
      {children}
    </MuiLink>
  )
}

const QuestionTable = ({ questions, moduleId }) => {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="question table">
        <TableHead>
          <TableRow key={'question_header'}>
            <TableCell></TableCell>
            <TableCell>Question</TableCell>
            {/* <TableCell>Lesson</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {questions.map((question, index) => (
            <TableRow key={'question_' + index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row">
                <CancelIcon fontSize="medium" sx={{ color: red[500] }} />
              </TableCell>
              <TableCell>
                <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]}>
                  {question.question}
                </ReactMarkdown>
              </TableCell>
              {/* <TableCell>
                <ExternalLink href={`/module/${moduleId}/lesson/${question.lesson_uuid}`}>Lesson</ExternalLink>
              </TableCell> */}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default QuestionTable
