import React, { useState } from 'react'
import { Button, CircularProgress, TextField, Typography, Box, Link, Snackbar, IconButton } from '@mui/material'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { handleFileUpload } from 'admin/utils/file-uploader'
const fileUploadPath = 'uploads/'
const ui_env = document.location.hostname
let s3BucketName = 'examfly-shared-content-dev'

switch (ui_env) {
  case 'dev.examfly-app.com':
    s3BucketName = 'examfly-shared-content-dev'
    break
  case 'staging.examfly-app.com':
    s3BucketName = 'examfly-shared-content-staging'
    break
  case 'www.examfly-app.com':
    s3BucketName = 'examfly-shared-content-prod'
    break
  default:
    s3BucketName = 'examfly-shared-content-dev'
    break
}

const UploadShareableContentLink = () => {
  const [file, setFile] = useState(null)
  const [uploading, setUploading] = useState(false)
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(null)
  const [snackbarOpen, setSnackbarOpen] = useState(false)

  const handleFileChange = (event) => {
    setFile(event.target.files[0])
  }

  const handleUpload = async () => {
    if (!file) return

    setUploading(true)
    setError(null)
    setSuccess(null)
    const safeFileName = encodeURI(file.name.replace(/[^a-zA-Z0-9.]/g, '-')) //regex filename
    
    try {
      await handleFileUpload(
        file,
        safeFileName,
        fileUploadPath,
        s3BucketName,
        (fileUrl) => setSuccess(fileUrl),
        (fileUrl) => setError(fileUrl)
      )
    } catch (err) {
      setError(err.message)
    } finally {
      setUploading(false)
    }
  }

  const handleCopyToClipboard = () => {
    if (success) {
      navigator.clipboard.writeText(success)
      setSnackbarOpen(true)
    }
  }

  const handleSnackbarClose = () => {
    setSnackbarOpen(false)
  }

  return (
    <Box display="flex" flexDirection="column" alignItems="center" p={2} gap={2}>
      <TextField
        type="file"
        onChange={handleFileChange}
        variant="outlined"
        sx={{
          minWidth: '60%',
          width: 'fit-content',
        }}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          accept: '*/*',
        }}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={handleUpload}
        disabled={uploading || !file}
        sx={{
          width: '60%',
          alignSelf: 'center',
        }}
      >
        {uploading ? <CircularProgress size={24} /> : 'Upload File'}
      </Button>
      {uploading && (
        <Box display="flex" alignItems="center" gap={1}>
          <CircularProgress size={24} />
          <Typography>Uploading File...</Typography>
        </Box>
      )}
      {success && (
        <Box display="flex" flexDirection="column" alignItems="center" gap={1}>
          <Typography>Success: your file is available from this link: </Typography>
          <Box display="flex" flexDirection="row" alignItems="center">
            <Link href={success} target="_blank" rel="noopener noreferrer">
              {success}
            </Link>
            <IconButton variant="outlined" onClick={handleCopyToClipboard}>
              <ContentCopyIcon />
            </IconButton>
          </Box>
        </Box>
      )}
      {error && <Typography color="error">Failure: {error}, please try again</Typography>}
      <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={handleSnackbarClose} message="URL copied to clipboard" />
    </Box>
  )
}

export default UploadShareableContentLink
