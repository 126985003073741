import { Box, Typography, Paper, ListItemButton, ListItem, ListItemText, List, ListItemSecondaryAction, ListItemAvatar, Avatar, CircularProgress } from '@mui/material'
import React from 'react'
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone'
import DoNotDisturbOnTwoToneIcon from '@mui/icons-material/DoNotDisturbOnTwoTone'
import StopWatchImage from '../dashboard/stopwatch.svg'
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight'
const AssessmentListItem = ({ name, id, isCompleted, imageUrl, attemptsRemaining }) => {
  if (!imageUrl) {
    imageUrl = `${StopWatchImage}`
  }
  return (
    <ListItem divider>
      <ListItemButton aria-label="Enter Assessment" href={'/assessment/' + id} sx={{ marginRight: '0px' }}>
        <ListItemAvatar>
          <Avatar
            sx={{
              // filter: isCompleted ? 'none' : 'grayscale(100%)',
              height: '50px',
              width: '50px',
              marginRight: '20px',
              objectFit: 'cover',
            }}
            variant="square"
            alt={name}
            src={process.env.NODE_ENV === 'development' ? 'https://dev.examfly-app.com' + imageUrl : imageUrl}
          ></Avatar>
        </ListItemAvatar>

        {/* <ListItemText primary={name} secondary={isCompleted ? 'Assessment Completed' : 'Start Assessment '} /> */}
        <ListItemText
          variant="body1"
          color="text.secondary"
          sx={{
            '.MuiListItemText-secondary': {
              display: 'block',
              color: '#1756A3',
              fontSize: '1.2rem',
              '&:hover': {
                textDecoration: 'underline', // Underline only the secondary text on hover
              },
            },
          }}
          primary={name}
          secondary={isCompleted ? 'Assessment Completed' : 'Start Assessment '}
        />

        <ListItemSecondaryAction
          sx={{
            // filter: isCompleted ? 'none' : 'grayscale(100%)',
            // height: '50px',
            // width: '50px',
            marginRight: '0px',
          }}
        >
          <ArrowCircleRightIcon aria-label="Enter Assessment" sx={{ color: '#1756A3' }} fontSize="large" />
          {/* {isCompleted ? (
          <CheckCircleTwoToneIcon aria-label="Assessment Completed" color={'greenTint'} fontSize="large" />
        ) : (
          <DoNotDisturbOnTwoToneIcon aria-label="Assessment Not Completed" color={'notAchieved'} fontSize="large" />
        )} */}
        </ListItemSecondaryAction>
      </ListItemButton>
    </ListItem>
  )
}
const AssessmentCompletedPanel = ({ assessments }) => {
  const loading = false

  return (
    <Box sx={{ background: '#FFFFFF', height: '100%', paddingBottom: '20px', display: 'flex', flexDirection: 'column', overflow: 'auto' }}>
      {loading ? (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={{ background: '#FFFFFF' }}>
          <Box sx={{ p: 0, pb: 0, background: '#1756A3' }}>
            <Paper sx={{ padding: '10px', backgroundColor: '#1756A3' }}>
              <Typography variant="body1" color="#FFFFFF">
                Test your knowledge with the Module Assessments. <br />
                The assessments must to completed to gain your proficiencies.
              </Typography>
            </Paper>
          </Box>
          <List sx={{ mx: 'auto' }}>
            {assessments.map((assessment, index) => (
              <AssessmentListItem
                key={`assessmentlistitem-${assessment.uuid}`}
                id={assessment.uuid}
                name={assessment.name}
                imageUrl={assessment.imageUrl}
                isCompleted={assessment.isCompleted}
              />
            ))}
          </List>
        </Box>
      )}
    </Box>
  )
}

export default AssessmentCompletedPanel
