import React, { useEffect, useState } from 'react'
import { Accordion, AccordionSummary, Box, Button, Stack, Chip, Link, Grid, Typography, CircularProgress, AccordionDetails } from '@mui/material'
import MainCard from '../components/MainCard'
import LinearWithValueLabel from '../components/linear-progress-with-label'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import PlayCircleIcon from '@mui/icons-material/PlayCircle'
import PropTypes from 'prop-types'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import { PastPapersComponent, checkIfPastPaperExists } from './past-papers'
import { PDFComponent, checkIfPDFExists } from './pdfs'

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  )
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}
const ExploreModule = (props) => {
  const { moduleId } = useParams()
  const [loading, setLoading] = useState(true)
  const [module, setModule] = useState({})
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  useEffect(() => {
    if (loading) {
      axios(`/module/${moduleId}/explore`).then((r) => {
        setModule(r.data)
        setLoading(false)
      })
    }
  }, [moduleId])

  if (loading)
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          height: '1000px',
        }}
      >
        <CircularProgress />
      </Box>
    )

  const completedLessonsCount = module.order.filter((lessonId) => module.lessons[lessonId].isCompleted).length
  const completionPercentage = (completedLessonsCount / module.order.length) * 100

  return (
    <>
      <Box>
        <Link sx={{ marginRight: '10px' }} href="/">
          Dashboard
        </Link>
        &#62;
        <Link sx={{ marginLeft: '10px', marginRight: '10px' }} href={`/module/${moduleId}/explore`}>
          {module.name}
        </Link>
      </Box>
      <Typography sx={{ marginBottom: '20px', textAlign: 'center' }} variant="h3">
        Module Overview
      </Typography>

      <Grid container sx={{ margin: '0px 0px 20px 0px' }} justifyContent={'center'} spacing={2}>
        <Grid sx={{ padding: '0 10px 0px 0px', maxWidth: '470px' }} item key={1}>
          <Box sx={{ padding: '10px 0px 10px 0px' }}>
            <Typography variant="h2" sx={{ margin: '0px 10px 0px 0px' }}>
              {module.name}
            </Typography>
          </Box>
        </Grid>
        <Grid sx={{ maxWidth: '470px', p: 0 }} item key={2}>
          <MainCard sx={{ margin: '0px 0px 20px 0px' }} content={false}>
            <Box sx={{ padding: '10px', width: '350px', marginTop: '0px' }}>
              <Typography variant="h6">Progress</Typography>
              <LinearWithValueLabel color="secondary" variant="determinate" size={150} value={completionPercentage} />
            </Box>
          </MainCard>
        </Grid>
      </Grid>

      {checkIfPastPaperExists(moduleId) && (
        <Grid container sx={{ margin: '0px 0px 20px 0px' }} justifyContent={'center'} spacing={1}>
          <PastPapersComponent moduleId={moduleId} />
        </Grid>
      )}

      {loading ? (
        <CircularProgress />
      ) : (
        <Grid container justifyContent={'center'} spacing={1}>
          {module.order.map((lessonId, index) => (
            <Grid sx={{ maxWidth: '470px', width: '100%' }} item key={lessonId}>
              <Lesson {...module.lessons[lessonId]} moduleId={moduleId} lessonIndex={index} />
            </Grid>
          ))}
        </Grid>
      )}

      {checkIfPDFExists(moduleId) && (
        <Grid container sx={{ margin: '0px 0px 20px 0px' }} justifyContent={'center'} spacing={1}>
          <PDFComponent moduleId={moduleId} />
        </Grid>
      )}
    </>
  )
}

const Lesson = ({ isCompleted, elements, imageUrl, moduleId, name, order, types, uuid, time_to_complete, lessonIndex }) => {
  const firstUnCompletedIndex = order.findIndex((id) => !elements[id].isCompleted)
  let buttonLabel = ''
  let linkToUuid = ''
  if (firstUnCompletedIndex === 0) {
    linkToUuid = order[0]
    buttonLabel = 'Start'
  } else if (isCompleted) {
    linkToUuid = order[0]
    buttonLabel = 'Restart'
  } else {
    linkToUuid = order[firstUnCompletedIndex]
    buttonLabel = 'Resume'
  }
  const hours = Math.floor(time_to_complete / 60)
  const minutes = time_to_complete % 60
  const minutesText = minutes > 1 ? ' minutes' : 'minute'

  return (
    <Box
      id={uuid}
      sx={{
        background: '#FFFFFF',
        borderRadius: '5px',
        margin: '5px',
        position: 'relative',
      }}
    >
      {imageUrl ? (
        <>
          <Box
            sx={{
              position: 'relative',
              // '& :hover': {
              //   backgroundColor: 'primary.main',
              //   opacity: [0.9, 0.8, 0.7],
              // },
            }}
          >
            {isCompleted && (
              <CheckCircleIcon
                color="greenTint"
                sx={{
                  backgroundColor: '#FFFFFF',
                  padding: 0,
                  borderRadius: '50%',
                  width: '50px',
                  height: '50px',
                  position: 'absolute',
                  top: '10px',
                  left: '10px',
                  zIndex: 1,
                }}
              />
            )}
            <Box
              sx={{
                textAlign: 'center',
                color: 'primary.main',
                // backgroundColor: '#8e24aa',
                backgroundColor: '#7bdcb5',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '0px',
                width: '100%',
                height: '48px',
                position: 'absolute',
                top: '0px',
                left: '0px',
              }}
            >
              <Typography sx={{ color: 'white', marginLeft: '4px', fontSize: '16px', padding: '4px', fontWeight: '800' }}>
                {lessonIndex + 1}. {name}
              </Typography>
            </Box>
            <Link href={`/module/${moduleId}/lesson/${uuid}/${linkToUuid}`} sx={{ padding: '0', margin: '0' }}>
              <Box
                sx={{
                  width: '100%',
                  height: '250px',
                  backgroundSize: 'cover',
                  backgroundImage: `url("${process.env.NODE_ENV === 'development' ? 'https://dev.examfly-app.com' : ''}${imageUrl}")`,
                }}
              ></Box>
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  bottom: 0,
                  left: 0,
                  right: 0,
                  height: '100%',
                  width: '100%',
                  opacity: 0,
                  transition: '.3s ease',
                }}
              >
                <PlayCircleIcon
                  sx={{
                    color: 'white',
                    fontSize: '100px',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    textAlign: 'center',
                  }}
                />
              </Box>
            </Link>
          </Box>
          {time_to_complete && time_to_complete > 0 ? (
            <Box
              sx={{
                textAlign: 'center',
                color: 'primary.main',
                backgroundColor: '#F5F5F5',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '4px',
              }}
            >
              <AccessTimeIcon sx={{ fontSize: '1.2rem' }} />
              <Typography sx={{ color: 'primary.main', marginLeft: '4px' }}>
                Estimated time to complete : {hours > 0 ? `${hours}h` : ''} {minutes > 0 ? ` ${minutes}${minutesText}` : ''}
              </Typography>
            </Box>
          ) : (
            ''
          )}
        </>
      ) : (
        <Typography variant="h2" sx={{ paddingTop: '60px' }}>
          {lessonIndex + 1} {name}
        </Typography>
      )}
      <Box sx={{ padding: '20px' }}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Lesson Content:</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {order.map((id, index) => (
              <LessonElement
                index={index}
                isCompleted={elements[id].isCompleted}
                name={elements[id].name}
                moduleId={moduleId}
                lessonId={uuid}
                key={id}
                uuid={id}
                type={types[id]}
              />
            ))}
          </AccordionDetails>
        </Accordion>
      </Box>
      <Link href={`/module/${moduleId}/lesson/${uuid}/${linkToUuid}`}>
        <Button sx={{ display: 'block', width: '100%', borderRadius: 0 }} variant="contained">
          {buttonLabel}
        </Button>
      </Link>
    </Box>
  )
}

const LessonElement = ({ isCompleted, index, lessonId, moduleId, name, type, uuid }) => {
  return (
    <Box>
      <Link sx={{}} href={`/module/${moduleId}/lesson/${lessonId}/${uuid}`}>
        <Chip
          color={isCompleted ? 'greenTint' : 'primary'}
          sx={{
            marginRight: '10px',
            marginTop: '10px',
            textAlign: 'left',
            '&:hover': { cursor: 'pointer' },
          }}
          icon={isCompleted ? <CheckCircleIcon color="white" /> : null}
          label={`${index + 1}. ${name}`}
        />
      </Link>
    </Box>
  )
}

export default ExploreModule
