import { Box, Button, CircularProgress, Grid, Fade, List, ListItem, ListItemIcon, ListItemText, Alert, AlertTitle, Paper } from '@mui/material'
import { Link, Typography } from '@mui/material'
import { useEffect, useState, useRef } from 'react'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom'
import MainCard from 'components/MainCard'
import theme from 'theme'
import Question from './question'
import CountDown from './countdown'
import StartAssessmentModal from './start-assessment-modal'
import QuestionTable from './questions-table'
import { useParams } from 'react-router-dom'
import Divider from '@mui/material/Divider'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import mixpanel from 'mixpanel-browser'
import Confetti from 'react-confetti'
import useWindowSize from 'react-use/lib/useWindowSize'
import CancelIcon from '@mui/icons-material/Cancel'
import { red } from '@mui/material/colors'
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle'

const AssessmentSession = (props) => {
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState(true)
  const [questions, setQuestions] = useState([])
  const [currentQuestion, setCurrentQuestion] = useState(false)
  const [incorrectAnswers, setIncorrectAnswers] = useState([])
  const [assessment, setAssessment] = useState({})
  const { assessmentId } = useParams(false)
  const [countDownEnabled, setCountDownEnabled] = useState(false)
  const [assessmentDuration, setAssessmentDuration] = useState(false)
  const [assessmentName, setAssessmentName] = useState('')
  const [passPercentage, setPassPercentage] = useState(false)
  const [resultPercentage, setResultPercentage] = useState(false)
  const [numQuestions, setNumQuestions] = useState(false)
  const [assessmentAttemptsRemaining, setAssessmentAttemptsRemaining] = useState(3)
  const [assessmentSessionId, setAssessmentSessionId] = useState(false)
  const [assessmentStatus, setAssessmentStatus] = useState(false)
  const [module, setModule] = useState({})
  const [questionResults, setQuestionResults] = useState([])
  const [maxAttempts, setMaxAttempts] = useState(0)

  /**
   * Screen 1: "Get Ready for Assessment" `screen
   * Screen 2: "The Questions" screen
   * Screen 3: "Assessment Completed" screen
   * Screen 4: "Time Expired" screen
   * Screen 5: "Attempts Exceeded" screen
   */
  const [screen, setScreen] = useState(1)
  const countdownRef = useRef()

  useEffect(() => {
    axios(`/assessment/${assessmentId}`)
      .then((response) => {
        setQuestions(response.data.assessmentQuestions)
        setAssessment(response.data)
        setAssessmentName(response.data.name)
        setAssessmentDuration(response.data.duration)
        setPassPercentage(response.data.passPercentage)
        setNumQuestions(response.data.numQuestions)
        setMaxAttempts(response.data.maxAttempts)
        setAssessmentAttemptsRemaining(response.data.attemptsRemaining)
        setModule(response.data.module)
        setAssessmentStatus(response.data.status)
        setLoading(false)
      })
      .catch((error) => {
        console.log('[Error]', error)
        enqueueSnackbar('Something went wrong!', { variant: 'error' })
        setLoading(false)
      })
  }, [assessmentId, enqueueSnackbar])

  const startAssessment = async () => {
    axios
      .post(`/assessment/session/`, {
        assessmentId,
      })
      .then(({ data }) => {
        setAssessmentSessionId(data.id)
        setCountDownEnabled(true)
        setAssessmentAttemptsRemaining(data.assessmentAttemptsRemaining)
        countdownRef.current.start()
        setScreen(2) // set screen to 2 to start assessment
        setCurrentQuestion(0) // set current question to the first question
      })
      .catch((error) => {
        if (error.response.status === 429) {
          handleAttemptsExceeded()
        } else {
          console.log('[Error]', error)
          enqueueSnackbar('Something went wrong!', { variant: 'error' })
          setLoading(false)
        }
      })
  }

  const handleTimeExpired = () => {
    setCountDownEnabled(false)
    countdownRef.current.stop()
    console.log('Time Expired')
    setScreen(4)
  }

  const handleAttemptsExceeded = () => {
    setCountDownEnabled(false)
    countdownRef.current.stop()
    console.log('Attempts Exceeded')
    setScreen(5)
  }

  const handleCompletedAssessment = () => {
    // stop the countdown
    setCountDownEnabled(false)
    countdownRef.current.stop()

    axios
      .get(`/assessment/${assessmentId}/session/${assessmentSessionId}`)
      .then(({ data }) => {
        setResultPercentage(data.assessmentResultPercentage)
        setQuestionResults(data.incorrectAnswers)
        setScreen(3)
      })
      .catch((error) => {
        console.log('Error', error)
        enqueueSnackbar('Something went wrong!', { variant: 'error' })
        setLoading(false)
      })
  }

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '30vh' }}>
        <CircularProgress />
      </Box>
    )
  }

  const isLastQuestion = currentQuestion === questions.length - 1

  return (
    <Box>
      <Link sx={{ marginRight: '10px' }} href="/">
        Dashboard
      </Link>
      &#62;
      <Link sx={{ marginLeft: '10px', marginRight: '10px' }} href={`/assessment/` + assessmentId}>
        Module Assessment
      </Link>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '20px auto' }}>
        <Typography sx={{ margin: 0, color: '#004693' }} variant="h2">
          {assessmentName}
        </Typography>
      </Box>
      <Box sx={{ padding: 0.2 }}>
        <Grid container spacing={4} flexWrap="wrap">
          <Grid item xs={12} sm={8}>
            <MainCard
              sx={{
                position: 'relative',
                backgroundColor: 'white',
                overflow: 'hidden',
                minHeight: '600px',
                paddingTop: '10px',
                '&::before': {
                  content: '""',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundImage: 'url(/images/stopwatch.svg)',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'top',
                  opacity: 0.1,
                  zIndex: 1,
                },
                '& > *': {
                  position: 'relative',
                  zIndex: 2,
                },
              }}
              border={false}
            >
              {screen === 1 && (
                <GetReadyToStartScreen
                  startAssessment={startAssessment}
                  assessmentDuration={assessmentDuration}
                  passPercentage={passPercentage}
                  numQuestions={numQuestions}
                  assessmentAttemptsRemaining={assessmentAttemptsRemaining}
                  maxAttempts={maxAttempts}
                  module={module}
                  assessmentStatus={assessmentStatus}
                />
              )}
              {screen === 3 && (
                <CompletedScreen
                  passPercentage={passPercentage}
                  resultPercentage={resultPercentage}
                  assessmentAttemptsRemaining={assessmentAttemptsRemaining}
                  questionResults={questionResults}
                  moduleId={module?.uuid}
                />
              )}
              {screen === 4 && <TimeExpiredScreen assessmentAttemptsRemaining={assessmentAttemptsRemaining} />}
              {currentQuestion < questions.length && screen === 2 && (
                <Question
                  assessmentId={assessmentId}
                  handleTimeExpired={handleTimeExpired}
                  assessmentSessionId={assessmentSessionId}
                  questionNumber={currentQuestion + 1}
                  totalQuestions={questions.length}
                  key={`question-${questions[currentQuestion].uuid}`}
                  questionId={questions[currentQuestion].uuid}
                  next={(wasCorrect, question) => {
                    let updatedIncorrectAnswers = [...incorrectAnswers]
                    if (!wasCorrect && question) {
                      updatedIncorrectAnswers.push(question)
                      setIncorrectAnswers(updatedIncorrectAnswers)
                      if (isLastQuestion) {
                        if (questions.length === updatedIncorrectAnswers.length) {
                          handleCompletedAssessment()
                        }
                      }
                    }

                    setCurrentQuestion(currentQuestion + 1)
                  }}
                  isLastQuestion={isLastQuestion}
                />
              )}
              {screen === 5 && <AttemptsExceeded />}
            </MainCard>
          </Grid>
          <Grid item xs={4} sx={{ display: { xs: 'none', sm: 'flex' } }}>
            <Grid container spacing={5} display="flex" flexDirection="column">
              <Grid item>
                <Box sx={{ display: { xs: 'none', sm: 'flex' } }}>
                  {assessmentDuration && <CountDown ref={countdownRef} startCountdown={countDownEnabled} duration={assessmentDuration} handleTimeExpired={handleTimeExpired} />}
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}
const GetReadyToStartScreen = ({ startAssessment, assessmentDuration, passPercentage, numQuestions, assessmentAttemptsRemaining, maxAttempts, module, assessmentStatus }) => {
  const [showStartAssessmentModal, setShowAssessmentModal] = useState(false)
  return (
    <Fade in={true}>
      <Box>
        <Box width="100%">
          <Box sx={{ textAlign: 'center', padding: '0px', borderRadius: '8px' }}>
            <Typography variant="h3">Get Ready to Start</Typography>
            {assessmentStatus?.completed && (
              <Box sx={{ textAlign: 'left', padding: '0px', margin: '15px auto', width: '500px' }}>
                <Alert severity="success" icon={<CheckCircleOutlineIcon fontSize="medium" />} sx={{ border: `1px solid ${theme.palette.success.light}` }}>
                  <AlertTitle>Assessment Completed</AlertTitle>
                  Congratulations! You have already completed the assessment. Thank you.
                </Alert>
              </Box>
            )}
            <Box sx={{ textAlign: 'left', padding: '0px', margin: '15px auto', width: '500px' }}>
              <Alert severity="warning" icon={<WarningAmberIcon fontSize="medium" />} sx={{ border: `1px solid ${theme.palette.warning.main}` }}>
                <AlertTitle sx={{ color: 'rgb(102, 60, 0)' }}>Info</AlertTitle>
                Ensure you have completed Module:{' '}
                <Link href={`/module/${module.uuid}/explore`} style={{ fontWeight: 'bold', color: theme.palette.primary.main, cursor: 'pointer' }}>
                  {module.name}
                </Link>{' '}
                before attempting this assessment.
              </Alert>
            </Box>
            <Typography>Read the instructions carefully before starting the assessment.</Typography>

            <List sx={{ width: '520px', margin: '0px auto', backgroundColor: 'white', padding: '4px' }}>
              <ListItem sx={{ padding: '2px 16px' }}>
                <ListItemIcon>
                  <LightbulbOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary={'Duration: ' + assessmentDuration + ' minutes'} />
              </ListItem>
              <ListItem sx={{ padding: '2px 16px' }}>
                <ListItemIcon>
                  <LightbulbOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary={'Number of Questions: ' + numQuestions} />
              </ListItem>
              <ListItem sx={{ padding: '2px 16px' }}>
                <ListItemIcon>
                  <LightbulbOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary={'Pass Percentage: ' + passPercentage} />
              </ListItem>
              <ListItem sx={{ padding: '2px 16px' }}>
                <ListItemIcon>
                  <LightbulbOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Type: Multiple Choice Questions " />
              </ListItem>
              {/* <ListItem sx={{ padding: '2px 16px' }}>
                <ListItemIcon>
                  <LightbulbOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="No going back " />
              </ListItem> */}
              <ListItem sx={{ padding: '2px 16px' }}>
                <ListItemIcon>
                  <ErrorOutlineIcon />
                </ListItemIcon>
                <ListItemText primary={'Maximum times assessment can be taken: ' + maxAttempts} />
              </ListItem>
              <ListItem sx={{ padding: '2px 16px' }}>
                <ListItemIcon>
                  <ErrorOutlineIcon />
                </ListItemIcon>
                <ListItemText primary={'You have ' + assessmentAttemptsRemaining + ' attempts left for this assessment. The highest scored attempt is taken as the final score.'} />
              </ListItem>
              <ListItem sx={{ padding: '2px 16px' }}>
                <ListItemIcon>
                  <ErrorOutlineIcon />
                </ListItemIcon>
                <ListItemText primary="Do not refresh your browser or navigate to another page as this will automatically end the assessment" />
              </ListItem>
            </List>
            <Divider sx={{ marginBottom: '10px' }} />

            {assessmentAttemptsRemaining === 0 ? (
              <>
                <Typography variant="h4">No remaining attempts</Typography>
                <Button sx={{ marginTop: '10px' }} variant="contained" disabled={true}>
                  Assessment Attempts Exceeded
                </Button>
              </>
            ) : (
              <>
                <Typography variant="subtitle2">
                  <Box component="span" fontWeight="fontWeightBold">
                    Good Luck.
                  </Box>{' '}
                  Click the button below to start the assessment.{' '}
                </Typography>
                <Button sx={{ marginTop: '10px' }} variant="contained" onClick={() => setShowAssessmentModal(true)}>
                  Start Assessment
                </Button>
              </>
            )}

            {showStartAssessmentModal && <StartAssessmentModal startAssessment={startAssessment} setShowAssessmentModal={setShowAssessmentModal} />}
          </Box>

          <Box sx={{ marginTop: '20px' }} textAlign="center">
            <BackToDashboard />
          </Box>
        </Box>
      </Box>
    </Fade>
  )
}

const CompletedScreen = ({ resultPercentage, passPercentage, assessmentAttemptsRemaining, questionResults, moduleId }) => {
  const { width, height } = useWindowSize()

  const passedAssessment = resultPercentage >= passPercentage
  return (
    <Fade in={true}>
      <Box width="100%">
        <Box sx={{ backgroundColor: '', textAlign: 'center', padding: '0px', borderRadius: '8px', minHeight: '500px' }}>
          <Typography variant="h3">Assessment Completed</Typography>

          <Alert
            icon={passedAssessment ? <CheckCircleOutlineIcon fontSize="medium" /> : <CancelIcon fontSize="medium" sx={{ color: red[500] }} />}
            severity={passedAssessment ? 'success' : 'error'}
            sx={{
              textAlign: 'left',
              width: '500px',
              margin: '20px auto',
              padding: '20px',
              border: `1px solid ${theme.palette.success.main}`,
            }}
          >
            <AlertTitle>You have completed the assessment. Thank you.</AlertTitle>

            {passedAssessment ? (
              <>
                <Confetti width={width} height={height} recycle={false} numberOfPieces={500} />
                <Typography sx={{ padding: '10px', fontWeight: 'bold' }}>Congratulations! You have achieved an Assessment Score of {resultPercentage} %</Typography>
              </>
            ) : (
              <>
                <Typography sx={{ padding: '10px', fontWeight: 'bold' }}>Your Assessment Score: {resultPercentage} %</Typography>
                <Typography sx={{ padding: '10px' }}>You have {assessmentAttemptsRemaining} remaining attempts for this assessment</Typography>
              </>
            )}
            <Typography sx={{ padding: '10px' }}>Score required to Pass: {passPercentage} %</Typography>

            <Typography sx={{ padding: '10px' }}>Click 'Back to Dashboard' and re-enter the assessment to try again.</Typography>
          </Alert>
          <Box textAlign="center">
            <BackToDashboard />
          </Box>
          <Divider />
          <Box sx={{ display: 'flex', margin: '20px auto' }}>
            {questionResults.length > 0 && (
              <Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',

                    marginBottom: 2, // Add some space below the header
                  }}
                >
                  <Typography variant="h3" sx={{ display: 'flex', alignItems: 'center' }}>
                    Review Incorrect Answers
                    <ArrowDropDownCircleIcon
                      sx={{
                        color: 'primary.main',
                        fontSize: 'inherit',
                        marginLeft: 1,
                        verticalAlign: 'middle',
                      }}
                    />
                  </Typography>
                </Box>
                <Box sx={{ justifyContent: 'left', textAlign: 'left', alignItems: 'left', margin: '20px auto' }}>
                  <Typography sx={{ padding: '10px' }}>
                    Below is a list of the questions you answered incorrectly. Be sure to review the questions and revisit the associated lesson to deepen your knowledge.
                  </Typography>
                </Box>
                <QuestionTable questions={questionResults} moduleId={moduleId} />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Fade>
  )
}

const TimeExpiredScreen = ({ assessmentAttemptsRemaining }) => {
  return (
    <Fade in={true}>
      <Box>
        <Box width="100%">
          <Box sx={{ backgroundColor: '', textAlign: 'center', padding: '0px', borderRadius: '8px' }}>
            <Typography variant="h3">The Assessment Time has expired</Typography>

            <Alert
              icon={<WarningAmberIcon fontSize="medium" />}
              severity="warning"
              sx={{ textAlign: 'left', width: '500px', margin: '20px auto', padding: '20px', border: `1px solid ${theme.palette.warning.main}` }}
            >
              <AlertTitle sx={{ color: 'rgb(102, 60, 0)' }}>Time Expired</AlertTitle>
              This is a timed assessment and you have exceeded the time. <br />
              Remaining attempts: {assessmentAttemptsRemaining} <br />
              Please read the instructions carefully. <br />
              <br />
              Click 'Back to Dashboard' and re-enter the assessment to try again.
            </Alert>
          </Box>
        </Box>
        <Divider />
        <Box textAlign="center">
          <BackToDashboard />
        </Box>
      </Box>
    </Fade>
  )
}

const AttemptsExceeded = () => {
  return (
    <Fade in={true}>
      <Box>
        <Box width="100%">
          <Box sx={{ backgroundColor: '', textAlign: 'center', padding: '0px', borderRadius: '8px' }}>
            <Typography variant="h3">Assessment Attempts Exceeded</Typography>
            <Alert icon={<WarningAmberIcon fontSize="large" />} severity="warning" sx={{ textAlign: 'left', width: '500px', margin: '20px auto', padding: '20px' }}>
              Sorry. You have reached the maximum number of attempts for this assessment.
              <br />
              <br />
              Please read the instructions carefully. <br />
              <br />
              You highest score will be recorded. <br />
              <br />
              Click 'Back to Dashboard' to return to the main page.
            </Alert>
          </Box>
        </Box>
        <Divider />
        <Box textAlign="center">
          <BackToDashboard />
        </Box>
      </Box>
    </Fade>
  )
}

const BackToDashboard = () => {
  const navigate = useNavigate()
  return (
    <Link
      style={{ fontWeight: 'bold', color: theme.palette.primary.main, cursor: 'pointer' }}
      to={`/`}
      onClick={(event) => {
        event.preventDefault()
        mixpanel.track('link_clicked', {
          Feature: 'assessment',
          Location: 'assessment_link_back_to_dashboard',
          Name: 'Back to Dashboard',
        })
        navigate(`/`)
      }}
    >
      Back to Dashboard
    </Link>
  )
}
export default AssessmentSession
