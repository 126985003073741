import React, { useEffect, useState } from 'react'
import { Box, Button, CircularProgress, LinearProgress, Typography } from '@mui/material'
import { Global, css } from '@emotion/react'
import Divider from '@mui/material/Divider'

import axios from 'axios'

import Summary from './summary'
import Feedback from '../results/feedback'
import { useLesson } from '../lesson/context'
import { randomList } from '../quiz/index'
import MobileNotSupported from '../components/MobileNotSupported'
import './index.css'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'
export const BUCKETS = 'BucketGame'

const globalStyles = css`
  @keyframes pulseShadow {
    0%,
    100% {
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 1);
    }
    25% {
      box-shadow: -5px 5px 10px rgba(0, 0, 0, 0.8);
    }
    50% {
      box-shadow: 5px 5px 10px rgba(0, 0, 0, 1);
    }
    75% {
      box-shadow: 5px -5px 10px rgba(0, 0, 0, 0.8);
    }
  }
  @keyframes pulseBorder {
    0% {
      box-shadow: 0 0 0 0 rgba(0, 123, 255, 0.7);
    }
    70% {
      box-shadow: 0 0 0 10px rgba(0, 123, 255, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(0, 123, 255, 0);
    }
  }
`

const Buckets = ({ next, uuid }) => {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState({})

  const [dragging, setDragging] = useState(false)
  const [moved, setMoved] = useState(false)
  const [selectedTarget, setSelectedTarget] = useState(null)
  const [checking, setChecking] = useState(false)

  const [currentIndex, setCurrentIndex] = useState(0)
  const [answers, setAnswers] = useState({})
  const [disableNext, setDisableNext] = useState(false)

  const { moduleId } = useLesson()

  useEffect(() => {
    if (!loading && currentIndex === data.items.length) {
      setDisableNext(true)
      axios({
        url: `/progress/`,
        method: 'post',
        data: {
          moduleId,
          type: BUCKETS,
          uuid: uuid,
        },
      }).then(() => {
        setDisableNext(false)
      })
    }
  }, [loading, currentIndex, data])

  useEffect(() => {
    if (loading) {
      axios(`/buckets/${uuid}`).then((r) => {
        setData({
          ...r.data,
          items: randomList(10, r.data.items),
        })
        setLoading(false)
      })
    }
  }, [uuid])

  useEffect(() => {
    if (selectedTarget) {
      const itemId = data.items[currentIndex].uuid
      axios(`/buckets/${uuid}/check`, {
        method: 'post',
        data: {
          itemId,
          moduleId,
          targetId: selectedTarget,
        },
      }).then((r) => {
        setAnswers({
          ...answers,
          [itemId]: {
            id: selectedTarget,
            correctTargets: r.data.correctTargets,
            isCorrect: r.data.isCorrect,
          },
        })
        setChecking(false)
      })
    }
  }, [selectedTarget])

  if (loading) {
    return <CircularProgress />
  }

  if (currentIndex === data.items.length) {
    return <Summary disableNext={disableNext} next={next} items={data.items} targets={data.targets} answers={answers} />
  }

  const { items, targets, question } = data
  const dragItem = items[currentIndex]

  const answeredCorrectly = answers[dragItem.uuid] && answers[dragItem.uuid].correctTargets.some((ct) => ct.uuid === selectedTarget)

  return (
    <>
      <Global styles={globalStyles} />
      <MobileNotSupported />
      <Box sx={{ display: { xs: 'none', sm: 'flex' }, flexDirection: 'column' }}>
        <Typography sx={{ alignSelf: 'flex-start', textAlign: 'left' }}>
          <Typography component="span" sx={{ margin: '5px', fontWeight: 'bold' }}>
            {currentIndex + 1}
          </Typography>
          of
          <Typography component="span" sx={{ margin: '5px', fontWeight: 'bold' }}>
            {data.items.length}
          </Typography>
        </Typography>
        <LinearProgress color={'greenTint'} variant="determinate" value={((currentIndex + 1) / data.items.length) * 100} />
        {data.description && <Description text={data.description} />}
        <Typography sx={{ textAlign: 'center', marginTop: '40px' }} variant="h3">
          {question}
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '40px' }}>
          <DragItem
            checking={checking}
            disabled={!!answers[dragItem.uuid]}
            onDragStart={(e) => {
              setMoved(true)
              setDragging(dragItem.uuid)
              e.dataTransfer.dropEffect = 'move'
              e.dataTransfer.setData('uuid', dragItem.uuid)
            }}
            onDragEnd={(e) => setDragging(false)}
            text={dragItem.text}
            image={dragItem.imageUrl}
            moved={moved}
          />
          <Box
            sx={{
              alignItems: 'space-between',
              display: 'flex',
              marginTop: '60px',
              alignSelf: 'center',
              width: `${targets.length * 300}px`,
              marginBottom: '20px',
            }}
          >
            {targets.map(({ imageUrl, text, uuid }, index) => {
              let isCorrect = null
              const showItem = answers[dragItem.uuid] && answers[dragItem.uuid].correctTargets.some((ct) => ct.uuid === uuid)
              if (selectedTarget === uuid) {
                isCorrect = answers[dragItem.uuid] && answers[dragItem.uuid].correctTargets.some((ct) => ct.uuid === uuid)
              }
              return (
                <DropTarget
                  checking={checking}
                  dragging={dragging}
                  key={index}
                  isCorrect={isCorrect}
                  showItem={showItem}
                  item={dragItem}
                  setDragging={setDragging}
                  onDrop={(uuid) => {
                    setSelectedTarget(uuid)
                    setChecking(true)
                  }}
                  imageUrl={imageUrl}
                  text={text}
                  uuid={uuid}
                  selected={selectedTarget === uuid}
                  selectedTarget={selectedTarget}
                />
              )
            })}
          </Box>
        </Box>
        {answers[dragItem.uuid] && (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Feedback
              feedback={
                <Typography component="div" sx={{ fontSize: '1.2rem' }}>
                  <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]}>
                    {dragItem.feedback}
                  </ReactMarkdown>
                </Typography>
              }
              isCorrect={answeredCorrectly}
            />
            <Button
              sx={{ alignSelf: 'center', marginTop: '20px' }}
              variant="contained"
              onClick={() => {
                setCurrentIndex(currentIndex + 1)
                setSelectedTarget(null)
                setMoved(false)
              }}
            >
              Next
            </Button>
          </Box>
        )}
      </Box>
    </>
  )
}

const DragItem = ({ checking, disabled, image, onDragEnd, onDragStart, text, moved }) => {
  return (
    <Box
      draggable={checking || disabled ? 'false' : 'true'}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      sx={{
        alignSelf: 'center',
        background: disabled ? '#1756A380' : '#1756A3',
        borderRadius: 200,
        display: 'flex',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        opacity: checking ? '0.25' : '1.00',
        padding: '20px',
        minHeight: '200px',
        minWidth: '200px',
        cursor: 'grab',
        position: 'relative',
        animation: moved ? 'none' : 'pulseShadow 4s infinite',
        zIndex: 1,
      }}
    >
      {/* HACK: class is there to make svg white */}
      {image && <img draggable="false" className="item-svg-img" src={image} />}
      {text &&
        text.split(' ').length <= 3 &&
        text.split(' ').map((word, index) => (
          <Typography key={`top-word-${index}`} sx={{ textAlign: 'center', fontWeight: 600, color: '#ffffff', fontSize: '1.1rem', width: '150px' }}>
            {word}
          </Typography>
        ))}
      {text && text.split(' ').length > 3 && <Typography sx={{ textAlign: 'center', fontWeight: 600, color: '#ffffff', fontSize: '1rem', width: '150px' }}>{text}</Typography>}
    </Box>
  )
}

const DropTarget = ({ checking, imageUrl, isCorrect, item, onDrop, selected, setDragging, showItem, text, uuid, selectedTarget }) => {
  const [over, setOver] = useState(false)
  let border = over || selected ? '2px solid #1756a380' : '2px dashed #80CCDC'
  if (typeof isCorrect === 'boolean') {
    border = isCorrect ? '2px solid #5EC2A6' : '2px solid #EB464C'
  }
  return (
    <Box
      key={`${uuid}${Math.random()}`}
      sx={{
        borderRadius: 2,
        border,
        background: over ? '#F2F6F7' : 'transparent',
        backgroundImage: over ? 'none' : 'radial-gradient(circle, #ffffff, #ddeeff)',
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'flex-start',
        opacity: checking ? '0.25' : '1.00',
        padding: '20px',
        minHeight: '250px',
        flex: '1 1 0px',
        marginRight: '40px',
        '&:last-child': {
          marginRight: 0,
        },
        animation: !!selectedTarget ? 'none' : 'pulseBorder 2s infinite',
      }}
      dropzone="move"
      onDrop={(e) => {
        e.preventDefault()
        onDrop(uuid)
        setOver(false)
        setDragging(false)
      }}
      onDragEnter={(e) => {
        setOver(true)
        e.preventDefault()
      }}
      onDragLeave={(e) => setOver(false)}
      onDragOver={(e) => e.preventDefault()}
    >
      {imageUrl && <Box component="img" src={imageUrl} sx={{ alignSelf: 'center', height: '50px', pointerEvents: 'none' }} />}
      <Typography sx={{ textAlign: 'center', pointerEvents: 'none', fontWeight: 600, fontSize: '1.1rem' }}>{text}</Typography>
      <Divider
        aria-hidden="true"
        variant="middle"
        sx={{
          border: '1px solid black',
          width: '80%',
          margin: '3px 0px 3px 0px',
        }}
      />
      {showItem && (
        <Box
          sx={{
            borderRadius: 100,
            background: '#1756A380',
            color: '#FFFFFF',
            padding: '10px',
            textAlign: 'center',
            pointerEvents: 'none',
            fontWeight: 600,
            display: 'flex',
            flexDirection: 'column',
            alignContent: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '200px',
            minWidth: '200px',
          }}
        >
          {item.imageUrl && <img draggable="false" className="item-svg-img" src={item.imageUrl} />}
          {item.text &&
            item.text.split(' ').length <= 3 &&
            item.text.split(' ').map((word, index) => (
              <Typography key={`word-${index}`} sx={{ textAlign: 'center', fontWeight: 600, color: '#ffffff', fontSize: '1.1rem', width: '150px' }}>
                {word}
              </Typography>
            ))}
          {item.text && item.text.split(' ').length > 3 && (
            <Typography sx={{ textAlign: 'center', fontWeight: 600, color: '#ffffff', fontSize: '1.1rem', width: '150px' }}>{item.text}</Typography>
          )}
        </Box>
      )}
    </Box>
  )
}

const Description = ({ text }) => {
  return (
    <Box sx={{ backgroundColor: '#F2F6F7', padding: '20px' }}>
      {text.split(/\n/).map((line, index) => (
        <Typography key={`description-line-${index}`}>{line}</Typography>
      ))}
    </Box>
  )
}

export default Buckets
