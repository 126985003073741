import React, { useEffect, useState } from 'react'
import { Box, Button, CircularProgress, Divider, Table, TableBody, TableCell, TableRow, Card, Typography, CardHeader, SnackbarContent, Fade, Zoom } from '@mui/material'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import Feedback from './feedback'
import MobileNotSupported from '../../components/MobileNotSupported'
import { useLesson } from '../../lesson/context'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import DataGridComponent from '../../common-components/datagrid'
import { Global, css } from '@emotion/react'
export const POT_DATAGRID_GAME = 'PoTDataGridGame'

const globalStyles = css`
  @keyframes pulseBorder {
    0% {
      box-shadow: 0 0 0 0 rgba(23, 86, 163, 0.7);
    }
    70% {
      box-shadow: 0 0 0 10px rgba(23, 86, 163, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(23, 86, 163, 0);
    }
  }
`
const columns = [
  { field: 'item', headerName: 'Item', width: 200, sortable: false },
  {
    field: 'non_savings_income',
    headerName: 'Non-Savings Income £',
    width: 150,
    editable: true,
    sortable: false,
  },
  {
    field: 'savings_income',
    headerName: 'Savings Income £',
    width: 130,
    editable: true,
    sortable: false,
  },
  {
    field: 'dividends_income',
    headerName: 'Dividends Income £',
    width: 150,
    editable: true,
    sortable: false,
  },

  {
    field: 'row_total',
    headerName: 'Total £',
    description: 'Sums the row',
    sortable: false,
    type: 'number',
    width: 100,
    valueGetter: (params) => calculateTotal(params.row),
  },
]

const calculateTotal = (row) => {
  return Object.keys(row).reduce((sum, key) => {
    if (key !== 'id' && key !== 'row_total') {
      if (!isNaN(row[key])) {
        return sum + Number(row[key])
      }
    }
    return sum
  }, 0)
}

const initialRows = []

const PoTDataGridGame = ({ next, uuid = 'e0d535a7-5684-44db-ba1f-b58f6aa7c4ca' }) => {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState({})

  const { moduleId } = useLesson()

  useEffect(() => {
    if (loading) {
      axios(`/datagrid-game/${uuid}`).then((r) => {
        setData(r.data)
        setLoading(false)
      })
    }
  }, [uuid])

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', width: '100vw' }}>
        <CircularProgress />
      </Box>
    )
  }

  return <Calculation gameId={uuid} gameData={data} next={next} />
}

function isNumber(value) {
  return !isNaN(parseFloat(value)) && isFinite(value)
}

const createColumnTotals = (rows) => {
  const taxAllowance = rows[rows.length - 1]
  const rowsWithoutTaxAllowance = rows.splice(0, rows.length - 1)

  const net_income_totals = rowsWithoutTaxAllowance.reduce(
    (acc, row, index) => ({
      id: 'net_income_total',
      item: 'Net Income',
      non_savings_income: acc.non_savings_income + Number(row.non_savings_income),
      savings_income: acc.savings_income + Number(row.savings_income),
      dividends_income: acc.dividends_income + Number(row.dividends_income),
    }),
    { id: 'net_income_total', item: 'Net Income', non_savings_income: 0, savings_income: 0, dividends_income: 0 }
  )

  const sumColumns = [taxAllowance, net_income_totals].reduce(
    (acc, row, index) => {
      return {
        id: 'sum_columns',
        item: 'Taxable Income',
        non_savings_income: acc.non_savings_income + Number(row.non_savings_income),
        savings_income: acc.savings_income + Number(row.savings_income),
        dividends_income: acc.dividends_income + Number(row.dividends_income),
      }
    },
    { id: 'sum_columns', item: 'Taxable Income', non_savings_income: 0, savings_income: 0, dividends_income: 0 }
  )
  return [...rowsWithoutTaxAllowance, net_income_totals, taxAllowance, sumColumns]
}

const Calculation = ({ gameId, next }) => {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState({})
  const [checking, setChecking] = useState(false)
  const [results, setResults] = useState([])
  const { moduleId } = useLesson()
  const [rows, setRows] = React.useState(initialRows)
  const [highlightedCells, setHighlightedCells] = React.useState([])
  const [allCorrect, setAllCorrect] = React.useState(false)
  const [feedback, setFeedback] = React.useState(false)
  const [wrongIndexes, setWrongIndexes] = useState([])
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)
  const [nextQuestion, setNextQuestion] = useState(0)
  const [wasDataGridChanged, setWasDataGridChanged] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const handleProcessRowUpdate = (updatedRow) => {
    const variableFields = ['non_savings_income', 'savings_income', 'dividends_income']

    const updatedRowWithNumbers = variableFields.reduce((acc, field) => {
      if (isNumber(updatedRow[field])) {
        acc[field] = Number(updatedRow[field])
      } else {
        acc[field] = 0
      }
      return acc
    }, updatedRow)

    const newRows = rows.filter((r) => r.id !== 'net_income_total' && r.id !== 'sum_columns').map((row) => (row.id === updatedRowWithNumbers.id ? updatedRowWithNumbers : row))

    setRows(createColumnTotals(newRows))
    setHighlightedCells([])
    setWasDataGridChanged(true)
    return updatedRow
  }

  useEffect(() => {
    axios(`/datagrid-game/${gameId}`).then((r) => {
      setData(r.data)

      setRows(createColumnTotals([...r.data.answerGridTemplate]))
      setLoading(false)
    })
  }, [gameId])

  useEffect(() => {
    if (allCorrect) {
      axios({
        url: `/progress/`,
        method: 'post',
        data: {
          moduleId,
          type: POT_DATAGRID_GAME,
          uuid: gameId,
        },
      }).then(() => {
        console.log('Progress updated')
      })
    }
  }, [allCorrect])

  if (loading) {
    return <CircularProgress />
  }
  const sendGridData = () => {
    if (wasDataGridChanged) {
      axios({
        url: `/datagrid-game/${gameId}/check`,
        method: 'post',
        data: {
          moduleId,
          gameId: gameId,
          grid: rows.filter((obj) => obj.id !== 'sum_columns' && obj.id !== 'net_income_total'),
          currentQuestionIndex: nextQuestion,
        },
      })
        .then((r) => {
          setResults(r.data)
          setWrongIndexes(r.data.wrongIndexes)
          if (r.data.wrongIndexes.length > 0) {
            setCurrentQuestionIndex(r.data.wrongIndexes[0])
            enqueueSnackbar(`Incorrect Answer. Enter the correct value in the correct cell. Try again`, { variant: 'warning' })
          }
          setNextQuestion(r.data.nextHighestId)
          setChecking(false)
          setFeedback(r.data.feedback)
          const highlightWrongCells = r.data.wrong.map((result) => {
            return {
              id: result.id,
              field: result.field,
              item: result.item,
            }
          })
          setAllCorrect(r.data.allCorrect)
          setHighlightedCells(highlightWrongCells) // Highlight multiple cells
          setWasDataGridChanged(false)
        })
        .catch((error) => {
          console.error('Error:', error)
        })
    } else {
      enqueueSnackbar(`Enter a income value into a cell first and click Check`, {
        variant: 'warning',
        // content: (key, message) => (
        //   <SnackbarContent
        //     style={{ fontWeight: 'bold', backgroundColor: '#ff9800' }} // Inline styles here
        //     message={message}
        //   />
        // ),
      })
    }
  }

  return (
    <>
      <MobileNotSupported />

      <Box sx={{ display: { xs: 'none', sm: 'flex', minWidth: '800px', maxWidth: '1200px', margin: '0px auto 0px auto' }, justifyContent: 'center' }}>
        <Box sx={{ backgroundColor: '#F2F6F7', padding: '10px', marginBottom: '1px', width: '100%' }}>
          <Typography variant="h3">{data.label}</Typography>
          <Typography>
            The aim of this game is to correctly categorise the different types of income in the Incomes Table below and apportion the personal allowance of £12,570 correctly.
          </Typography>
          <Typography variant="h4">
            Type the first income value in the correct row and category. <br />
            When you are ready click 'Check' to proceed to the next Income.
          </Typography>
        </Box>
      </Box>

      <Box sx={{ display: { xs: 'none', sm: 'flex' }, justifyContent: 'center' }}>
        <Box>
          <Typography variant="h4">Incomes</Typography>

          <Fade in={true}>
            <Table sx={{ background: '#F2F6F7' }}>
              <TableBody>
                {data.info.map((line, index) => {
                  if (nextQuestion === line.id) {
                    return <InfoLineCurrent key={`info-${index}`} {...line} sendGridData={sendGridData} tryAgain={wrongIndexes.length === 0 ? false : true} />
                  } else if (line.id < nextQuestion || allCorrect) {
                    return <InfoLineCompleted key={`info-${index}`} {...line} wasAnswered={true} />
                  } else {
                    return <InfoLine key={`info-${index}`} {...line} />
                  }
                })}
              </TableBody>
            </Table>
          </Fade>
          <InfoNote />
          {allCorrect && (
            <Box sx={{ marginTop: '10px', maxWidth: '470px' }}>
              <Feedback
                feedback={
                  <Typography component="div">
                    <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]}>
                      All correct! Game completed. Well done! Click Next below to go to the next Lesson
                    </ReactMarkdown>
                  </Typography>
                }
                isCorrect={true}
              />
            </Box>
          )}
        </Box>

        <Box sx={{ background: '#FFFFFF', display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ background: '#ffffff', margin: '0px 0px 0px 40px', maxWidth: '733px' }}>
            <Typography variant="h4">Tax Computation</Typography>
            <DataGridComponent rows={rows} columns={columns} handleProcessRowUpdate={handleProcessRowUpdate} highlightedCells={highlightedCells} />
          </Box>

          {allCorrect && feedback && (
            <Fade in={true}>
              <Box sx={{ margin: '10px 0px 0px 40px', maxWidth: '733px' }}>
                <Feedback
                  feedback={
                    <Typography component="div">
                      <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]}>
                        {feedback}
                      </ReactMarkdown>
                    </Typography>
                  }
                  isCorrect={true}
                />
              </Box>
            </Fade>
          )}
          {/* {results.wrong?.length > 0 && (
            <Box sx={{ marginTop: '10px', maxWidth: '733px' }} key={`question-wrong`}>
              <Feedback
                key={`feedback-wrong`}
                feedback={
                  <Typography component="div">
                    {results.wrong.map((wrongAnswer, index) => {
                      return (
                        <ReactMarkdown key={index} remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]}>
                          {wrongAnswer.feedback}
                        </ReactMarkdown>
                      )
                    })}
                    <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]}>
                      {feedback}
                    </ReactMarkdown>
                  </Typography>
                }
                isCorrect={false}
              />
            </Box>
          )} */}

          {/* {results.correct?.length > 0 && (
            <Box sx={{ margin: '10px 0px 0px 40px', maxWidth: '720px' }} key={`question-correct`}>
              <Feedback
                key={`feedback-correct`}
                feedback={
                  <Typography component="div">
                    {results.correct.map((correctAnswer, index) => {
                      return (
                        <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]}>
                          {correctAnswer.feedback}
                        </ReactMarkdown>
                      )
                    })}
                  </Typography>
                }
                isCorrect={true}
              />
            </Box>
          )} */}

          <Box sx={{ background: '#F2F6F7', display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '80px', padding: '5px' }}>
            {/* <Button sx={{ margin: '0 10px' }} onClick={sendGridData} variant="contained" color="primary">
              Check
            </Button> */}
            <Button sx={{ margin: '0 10px' }} variant="contained" onClick={() => next(true)}>
              Next
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  )
}

const InfoLineCurrent = ({ label, symbol, amount, sendGridData, wasAnswered, tryAgain }) => {
  return (
    <Fade in={true}>
      <TableRow sx={{ padding: '6px' }}>
        <TableCell sx={{ border: 0, padding: '4px' }}>{label}</TableCell>
        <TableCell sx={{ border: 0, padding: '4px' }}>
          <InfoValue amount={amount} symbol={symbol} />
        </TableCell>
        <TableCell sx={{ border: 0, padding: '4px', textAlign: 'center' }}>
          {sendGridData && (
            <>
              <Global styles={globalStyles} />
              <Button
                sx={{
                  margin: '0 8px',
                  animation: 'pulseBorder 2s infinite',
                  marginTop: '0px',
                  cursor: 'pointer',
                  borderRadius: 28,
                  border: '2px solid #1756A3',
                  fontSize: '14px',
                  fontWeight: 600,
                  width: '80px',
                  padding: '6px 18px',
                }}
                onClick={sendGridData}
                variant="contained"
                color="primary"
              >
                {tryAgain ? 'Retry' : 'Check'}
              </Button>
            </>
          )}
          {wasAnswered && (
            <CheckCircleIcon
              color="greenTint"
              sx={{
                padding: 0,
                width: '30px',
                height: '30px',
              }}
            />
          )}
        </TableCell>
      </TableRow>
    </Fade>
  )
}

const InfoNote = () => {
  return <Card sx={{ padding: '5px' }}>Don't forget to think about restrictions where the total income exceeds £100,000</Card>
}

const InfoLine = ({ label, symbol, amount, sendGridData, wasAnswered, tryAgain }) => {
  return (
    <TableRow sx={{ backgroundColor: '#1756A3', border: 1, padding: '6px', color: '#FFFFFF' }}>
      <TableCell sx={{ border: 0, padding: '6px', color: '#FFFFFF' }}>{label}</TableCell>
      <TableCell sx={{ border: 0, padding: '6px', color: '#FFFFFF' }}>
        <InfoValue amount={amount} symbol={symbol} />
      </TableCell>
      <TableCell sx={{ border: 0, padding: '6px', textAlign: 'center' }}>
        {sendGridData && (
          <>
            <Global styles={globalStyles} />
            <Button
              sx={{
                margin: '0 8px',
                animation: 'pulseBorder 2s infinite',
                marginTop: '0px',
                cursor: 'pointer',
                borderRadius: 28,
                border: '2px solid #1756A3',
                fontSize: '14px',
                fontWeight: 600,
                padding: '6px 18px',
              }}
              onClick={sendGridData}
              variant="contained"
              color="primary"
            >
              {tryAgain ? 'Retry' : 'Check'}
            </Button>
          </>
        )}
        {wasAnswered && (
          <CheckCircleIcon
            color="greenTint"
            sx={{
              padding: 0,
              width: '30px',
              height: '30px',
            }}
          />
        )}
      </TableCell>
    </TableRow>
  )
}

const InfoLineCompleted = ({ label, symbol, amount, sendGridData, wasAnswered, tryAgain }) => {
  return (
    <TableRow sx={{ padding: '6px', border: '1px solid #f0f0f0' }}>
      <TableCell sx={{ border: 0, padding: '4px', color: '#8c8c8c' }}>{label}</TableCell>
      <TableCell sx={{ border: 0, padding: '4px', color: '#8c8c8c' }}>
        <InfoValue amount={amount} symbol={symbol} />
      </TableCell>
      <TableCell sx={{ border: 0, padding: '4px', textAlign: 'center' }}>
        {sendGridData && (
          <>
            <Global styles={globalStyles} />
            <Button
              sx={{
                margin: '0 8px',
                animation: 'pulseBorder 2s infinite',
                marginTop: '0px',
                cursor: 'pointer',
                borderRadius: 28,
                border: '2px solid #1756A3',
                fontSize: '14px',
                fontWeight: 600,
                padding: '6px 18px',
              }}
              onClick={sendGridData}
              variant="contained"
              color="primary"
            >
              {tryAgain ? 'Retry' : 'Check'}
            </Button>
          </>
        )}
        {wasAnswered && (
          <Zoom in={true}>
            <CheckCircleIcon
              color="greenTint"
              sx={{
                padding: 0,
                width: '30px',
                height: '30px',
              }}
            />
          </Zoom>
        )}
      </TableCell>
    </TableRow>
  )
}

const InfoValue = ({ amount, symbol }) => {
  switch (symbol) {
    case 'ratio': {
      return amount
    }
    case '%': {
      return `${(amount * 100).toFixed(2)}%`
    }
    default: {
      return `${symbol}     ${amount} `
    }
  }
}

export default PoTDataGridGame
